import dayjs from 'dayjs'

const state = () => ({
  estimates: null,
  deleted: null,
  exam: null,
  baseline: null
})

const getters = {
  estimates: (state, getters, rootState) => {
    if (state.exam) {
      if (state.exam.age > 3) {
        return rootState.user.settings.showIdealWeight
          ? state.estimates
          : Array.isArray(state.estimates) && state.estimates.length
          ? state.estimates.filter(item => item.name !== 'idealWeight')
          : state.estimates
      } else if (state.estimates) {
        const newEstimates = state.estimates.map(estimate => {
          if (
            ['bmi', 'bcm', 'height', 'pha', 'ffm', 'fm', 'tbw'].includes(
              estimate.name
            )
          ) {
            return estimate
          } else {
            return {
              printable: true,
              name: estimate.name,
              title: rootState.dictionary[estimate.name] || estimate.name,
              value: 0,
              rawValue: 'N/A',
              referenceValue: null,
              rawReferenceValue: null,
              segments: null
            }
          }
        })
        return rootState.user.settings.showIdealWeight
          ? newEstimates
          : Array.isArray(state.estimates) && state.estimates.length
          ? newEstimates.filter(item => item.name !== 'idealWeight')
          : newEstimates
      }
    }
  },
  deleted: state => state.deleted,
  exam: state => state.exam,
  baseline: state => state.baseline
}

const mutations = {
  receiveEstimates(state, estimates) {
    state.estimates = estimates
  },
  addEstimates(state, estimatesArray) {
    estimatesArray.forEach(estimate => {
      state.estimates.push(estimate)
    })
  },
  addWeightToEstimates(state, weightEstimate) {
    state.estimates.unshift(weightEstimate)
  },
  togglePrintable(state, itemId) {
    state.estimates.find(
      item => item.id === itemId
    ).printable = !state.estimates.find(item => item.id === itemId).printable
  },
  receiveDeleted(state, exams) {
    state.deleted = exams
  },
  receiveExam(state, exam) {
    state.exam = exam
  },
  receiveBaseline(state, exam) {
    state.baseline = exam
  }
}

const actions = {
  getEstimates({ commit, rootState }, data) {
    return this.$axios
      .post('/bodygram-backend/v1/app/functions/estimates', {
        accessToken: rootState.user.token,
        examId: data.examId,
        type: data.type,
        formula: data.formula
      })
      .then(response => {
        const result = []

        let resultCount = 0

        for (const property in response.data.estimates) {
          if (
            property !== 'nutri' &&
            property !== 'hydra' &&
            property !== 'spa' &&
            property !== 'smi' &&
            response.data.estimates[property] !== null
          ) {
            let itemValue

            if (
              response.data.estimates[property].ticks.find(
                tick => tick.key === 'current'
              )
            ) {
              itemValue = response.data.estimates[property].ticks.find(
                tick => tick.key === 'current'
              ).value
            } else {
              itemValue = null
            }

            let itemReferenceValue

            if (
              response.data.estimates[property].ticks.find(
                tick => tick.key === 'baseline'
              )
            ) {
              itemReferenceValue = response.data.estimates[property].ticks.find(
                tick => tick.key === 'baseline'
              ).value
            } else {
              itemReferenceValue = null
            }

            let itemMeanValue

            if (
              response.data.estimates[property].ticks.find(
                tick => tick.key === 'mean'
              )
            ) {
              itemMeanValue = response.data.estimates[property].ticks.find(
                tick => tick.key === 'mean'
              ).value
            } else {
              itemMeanValue = null
            }

            const examItem = {
              name: property,
              title: rootState.dictionary[property] || property,
              value: itemValue,
              rawValue: response.data.estimates[property].ticks.find(
                tick => tick.key === 'absolute'
              )
                ? response.data.estimates[property].ticks.find(
                    tick => tick.key === 'absolute'
                  ).value
                : null,
              referenceValue: itemReferenceValue,
              rawReferenceValue: response.data.estimates[property].ticks.find(
                tick => tick.key === 'absolute-baseline'
              )
                ? response.data.estimates[property].ticks.find(
                    tick => tick.key === 'absolute-baseline'
                  ).value
                : null,
              meanValue: itemMeanValue,
              segments: null
            }

            const segments = []

            let item

            if (response.data.estimates[property].ranges) {
              item = response.data.estimates[property].ranges
            }
            if (item) {
              item
                .sort((a, b) => {
                  if (a.start > b.start) {
                    return 1
                  }
                  if (a.start < b.start) {
                    return -1
                  }
                  return 0
                })
                .forEach(segment => {
                  segments.push({
                    start: segment.start,
                    end: segment.end,
                    color: segment.color,
                    mean: segment.mean
                  })
                })
            }

            examItem.segments = segments
            examItem.printable = true
            examItem.id = resultCount

            result.push(examItem)

            resultCount++
          }
        }

        const resultSorted = []
        const sortOrder = [
          'idealWeight',
          'pha',
          'tbw',
          'ecw',
          'icw',
          'ffm',
          'fm'
        ]

        if (rootState.user.settings && rootState.user.settings.enableBcm) {
          sortOrder.push('bcm')
        }

        if (
          (response.data.exam.age >= 16 && rootState.user.settings.enableMm) ||
          (rootState.user.settings && rootState.user.settings.enableMm)
        ) {
          sortOrder.push('mm')
        }

        sortOrder.push('smm')
        sortOrder.push('asmm')
        sortOrder.push('bmr')
        sortOrder.push('tdee')

        if (rootState.user.settings && rootState.user.settings.enableNaeKe) {
          sortOrder.push('naeKe')
        }

        if (response.data.exam.age < 16) {
          if (response.data.exam.age >= 5) {
            sortOrder.unshift('bmi')
          }
          sortOrder.unshift('height')
        }

        if (response.data.exam.formulaVersion === 'sport') {
          sortOrder.push('alst')
          sortOrder.push('llst')
        }

        sortOrder.forEach(key => {
          const foundedItem = result.find(item => item.name === key)
          if (foundedItem) {
            resultSorted.push(foundedItem)
          } else {
            // console.log('item ' + key + ' not found')
          }
        })

        commit('receiveEstimates', resultSorted)
        commit('receiveExam', response.data.exam)
      })
  },
  getDeleted({ commit, rootState }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/list', {
        accessToken: rootState.user.token,
        patientId: patientId,
        filter: {
          deleted: true
        }
      })
      .then(response => {
        const exams = response.data.exams
        if (exams.length) {
          exams.forEach(item => {
            item.dateTime = dayjs(item.createdAt).format('L LT')
          })
        }
        commit('receiveDeleted', exams)
      })
  },
  restoreExam({ dispatch, rootState, rootGetters }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/restore', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/app/exams/delete`,
            {
              silent: true,
              id: examId
            }
          )
        }
      })
  },
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/get', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(response => {
        commit('receiveExam', response.data.exam)
      })
  },
  getBaseline({ commit, rootState }, patientId, formulaVersion = null) {
    return this.$axios
      .post('/bodygram-backend/v1/app/baseline/get', {
        accessToken: rootState.user.token,
        patientId: patientId,
        formulaVersion: formulaVersion
      })
      .then(response => {
        commit('receiveBaseline', response.data.exam)
      })
  },
  setBaseline({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/bodygram-backend/v1/app/baseline/set', {
        accessToken: rootState.user.token,
        examId: exam.id
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/app/baseline/set`,
            {
              silent: true,
              examId: exam.id
            }
          )
        }
        dispatch('patient/getExams', exam.patientId, { root: true })
      })
  },
  createExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/create', {
        accessToken: rootState.user.token,
        exam: exam
      })
      .then(response => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios
            .post(
              `${rootGetters['network/localAPI']}/bodygram-backend/v1/app/exams/create`,
              {
                silent: true,
                id: response.data.exam.id,
                exam: exam
              }
            )
            .catch(error => console.log(error))
        }
        dispatch('patient/getExams', exam.patientId, { root: true })
      })
  },
  updateExam({ dispatch, rootState, rootGetters }, exam) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/update', {
        accessToken: rootState.user.token,
        exam: exam
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/app/exams/update`,
            {
              silent: true,
              exam: exam
            }
          )
        }
        dispatch('getExam', exam.id)
      })
  },
  uploadImage({ dispatch, rootState }, data) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/update', {
        accessToken: rootState.user.token,
        exam: {
          id: data.examId
        },
        image: data.image
      })
      .then(() => {
        dispatch('getExam', data.examId)
      })
  },
  removeImage({ dispatch, rootState }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/update', {
        accessToken: rootState.user.token,
        exam: {
          id: examId,
          image: null
        }
      })
      .then(() => {
        dispatch('getExam', examId)
      })
  },
  deleteExam({ rootState, rootGetters }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/exams/delete', {
        accessToken: rootState.user.token,
        id: examId
      })
      .then(() => {
        if (
          window.$nuxt.$platform === 'electron' &&
          rootGetters['network/onLine']
        ) {
          this.$axios.post(
            `${rootGetters['network/localAPI']}/bodygram-backend/v1/app/exams/delete`,
            {
              silent: true,
              id: examId
            }
          )
        }
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
