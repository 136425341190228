import dayjs from 'dayjs'

const state = () => ({
  exam: null,
  references: null,
  sortedMeasurements: null
})

const getters = {
  exam: state => state.exam,
  references: state => state.references,
  sortedMeasurements: state => state.sortedMeasurements
}

const mutations = {
  receiveExam(state, exam) {
    state.exam = exam
  },
  receiveReferences(state, references) {
    state.references = references
  },
  clearExam(state) {
    state.exam = null
    state.references = null
  },
  receiveSortedMeasurements(state, sortedMeasurements) {
    state.sortedMeasurements = sortedMeasurements
  }
}

const actions = {
  getExam({ commit, rootState }, examId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/measurements/get', {
        accessToken: rootState.user.token,
        examId: examId
      })
      .then(response => {
        commit('receiveExam', response.data.measurement)
        commit('receiveReferences', response.data.references)
      })
      .catch(() => {
        commit('clearExam')
      })
  },
  setExam({ dispatch, rootState }, exam) {
    return this.$axios
      .post('/bodygram-backend/v1/app/measurements/set', {
        accessToken: rootState.user.token,
        measurement: exam
      })
      .then(() => {
        dispatch('getExam', exam.examId)
      })
  },
  deleteExam({ rootState }, examId) {
    return this.$axios.post('/bodygram-backend/v1/app/measurements/delete', {
      accessToken: rootState.user.token,
      examId: examId
    })
  },
  getSortedMeasurements({ commit, rootState }, patientId) {
    return this.$axios
      .post('/bodygram-backend/v1/app/reports/anthropometric-follow-up', {
        accessToken: rootState.user.token,
        patientId: patientId
      })
      .then(response => {
        const sortedMeasurements = response.data.results
          .map(item => ({
            ...item.measurement,
            number: item.number,
            isBaseline: item.isBaseline,
            effectiveAt: item.effectiveAt,
            dateTime: dayjs(item.effectiveAt).format('L LT')
          }))
          .sort((a, b) => {
            if (dayjs(a.effectiveAt).toDate() > dayjs(b.effectiveAt).toDate()) {
              return 1
            }
            if (dayjs(a.effectiveAt).toDate() < dayjs(b.effectiveAt).toDate()) {
              return -1
            }
            return 0
          })
        const baseline = sortedMeasurements.splice(
          sortedMeasurements.indexOf(
            sortedMeasurements.find(exam => exam.isBaseline)
          ),
          1
        )
        sortedMeasurements.unshift(...baseline)
        commit('receiveSortedMeasurements', sortedMeasurements)
      })
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
