import dayjs from 'dayjs'

const state = () => ({
  user: JSON.parse(localStorage.getItem('akern_customer_user')) || null,
  token: localStorage.getItem('akern_customer_token') || null,
  license: null,
  device: null,
  formulaVersion: null,
  softwareVersion: process.env.appVersion || '0',
  settings: null
})
const getters = {
  user: state => state.user,
  token: state => state.token,
  license: state => state.license,
  device: state => state.device,
  formulaVersion: state => state.formulaVersion,
  softwareVersion: state => state.softwareVersion,
  settings: state => state.settings,
  isAuthenticated: state => {
    if (state.user) {
      if (state.user.authorizedUntil) {
        const currentDate = dayjs().utc()
        const authorizedUntil = dayjs(state.user.authorizedUntil)
        const daysToLogout = authorizedUntil.diff(currentDate, 'day')
        return daysToLogout > 0
      } else {
        return true
      }
    } else {
      return false
    }
  },
  permissions: state => {
    if (state.user && state.user.permissions) {
      return state.user.permissions
    } else {
      return ['*']
    }
  }
}
const mutations = {
  receiveUser(state, user) {
    state.user = user
    localStorage.setItem('akern_customer_user', JSON.stringify(user))
  },
  receiveToken(state, token) {
    state.token = token
    localStorage.setItem('akern_customer_token', token)
  },
  logout(state) {
    state.token = null
    state.user = null
    localStorage.removeItem('akern_customer_token')
    localStorage.removeItem('akern_customer_user')
  },
  receiveLicense(state, license) {
    state.license = license
  },
  receiveDevice(state, device) {
    state.device = device
  },
  receiveFormulaVersion(state, formulaVersion) {
    state.formulaVersion = formulaVersion
  },
  receiveSoftwareVersion(state, softwareVersion) {
    state.softwareVersion = softwareVersion
  },
  receiveSettings(state, settings) {
    state.settings = settings
  },
  updateLocale(state, locale) {
    const user = JSON.parse(localStorage.getItem('akern_customer_user'))
    user.locale = locale
    localStorage.setItem('akern_customer_user', JSON.stringify(user))
    state.user = user
    state.settings.locale = locale
  },
  setGoogleAnalyticsUserId(state) {
    if (state.user && state.user.id && window.dataLayer) {
      window.dataLayer.push({
        event: 'user_id_set',
        user_id: state.user.id,
        application_type:
          process.env.platform === 'web' ? 'Web' : 'Desktop App',
        app_version: process.env.appVersion
      })
    }
  }
}
const actions = {
  login({ commit, rootGetters }, data) {
    if (
      window.$nuxt.$platform === 'electron' &&
      rootGetters['config/type'] === 'standalone'
    ) {
      this.$axios.post(
        `${rootGetters['network/localAPI']}/reset-download-date`,
        {}
      )
    }
    return this.$axios
      .post('http://localhost:5000/v1/customer/account/sign-in', data)
      .then(response => {
        commit('receiveToken', response.data.user.accessToken)
        commit('receiveUser', response.data.user)
      })
      .catch(error => {
        console.log(error)
        throw error
      })
  },
  forgot({ commit }, email) {
    return this.$axios.post(
      '/akern-platform-backend/v1/common/password/forgot',
      {
        email: email
      }
    )
  },
  reset({ commit }, data) {
    return this.$axios.post(
      '/akern-platform-backend/v1/common/password/reset',
      data
    )
  },
  logout({ commit }) {
    commit('logout')
    this.$router.push('/login')
  },
  getSettings({ state, commit }) {
    return this.$axios
      .post('/bodygram-backend/v1/settings/general/get', {
        accessToken: state.token
      })
      .then(response => {
        commit('receiveSettings', {
          ...response.data.settings,
          type: response.data.settings.type || 'standalone'
        })
      })
      .catch(error => {
        console.log(error)
      })
  },
  getLicense({ state, commit }) {
    return this.$axios
      .post('/bodygram-backend/v1/settings/licenses/get', {
        accessToken: state.token
      })
      .then(response => {
        commit('receiveLicense', response.data.license)
        commit('receiveDevice', response.data.device)
        commit('receiveFormulaVersion', response.data.formulaVersion)
        // commit('receiveSoftwareVersion', response.data.softwareVersion)
      })
      .catch(error => console.error(error))
  }
}
export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
